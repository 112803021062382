.list-container{
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 77%;
    margin: 0 auto;
  }
  
  .list-item{
    align-self: flex-start;
    margin-bottom: 10px !important;
  }

  .text-item{
    font-family: Arial, sans-serif;
    font-size: 16px; /* Örnek bir font boyutu */
    
    color: #333; /* Metin rengi */
   
  }

  .text-indent{
    text-indent: 20px; /* İndentasyon */
    line-height: 1.5; /* Satır boşluğu */
  }

  li{
    text-indent: -21px;
    line-height: 1.5; /* Satır boşluğu */
    margin-left: 15px ;
  }