.drawer-list {
  display: flex;
  flex-wrap: wrap; /* Kartlar sığmadığında satıra geçme */
  }

.grid-container {
  display: flex; /* Flex container olarak tanımla */
  flex:content;
  flex-wrap: wrap; /* Eğer flex itemlar yatay alana sığmazsa, alt satıra geç */
  justify-content: center; /* İçeriği yatayda ortala */
  align-items: center; /* İçeriği dikeyde ortala */
  height: 100vh; /* Ekran yüksekliğine kadar uzat */
  column-gap: 0; /* Sütunlar arasında boşluk olmasın */
  row-gap: 0;
  margin: 0 !important;
  padding: 0 !important;
  box-sizing: border-box;
}

.drawer-item {
  padding: 10px; /* Buton içeriğine dolgu ekle */
  border: 1px solid #000; /* Buton kenarlığını ayarla */
  display: flex; /* İçeriği dikey hizalamak için flex kullan */
  align-items: center; /* Dikey hizalama */
  justify-content: center; /* Yatay hizalama */
  text-align: center; /* Metni merkeze hizala */
  margin: 0; /* Üst ve alt boşluk olmaması için margin'ı sıfıra ayarla */
  
}

.dedede{
  width: 1200px !important;
  place-content: "space-between" !important;
  
}
